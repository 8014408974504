import {
    ManagePermitPage,
} from 'src/views/compliance/managepermit'
import{
    ViewPermitPage,
    UpdateDraftPermitPage
} from 'src/views/compliance'
import pathMap from 'src/paths/PathMap_Compliance'

class ComplianceRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.MANAGE_PERMIT,
                component: ManagePermitPage
            },
            {
                exact: true,
                path: pathMap.MANAGE_VIEW_PERMIT,
                component: ViewPermitPage
            },
            {
                exact: true,
                path: pathMap.CREATE_PERMIT_UPDATE_DRAFT,
                component: UpdateDraftPermitPage 
            }
        ]
    }
}

export default ComplianceRoute