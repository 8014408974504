import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
    AdminTablePage,
} from 'src/views/account-setup/accessleveluser'
import pathMap from 'src/paths/PathMap_AccountSetup'

class AccessLevelUserAdminRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.ACCESS_LEVEL_USER_LIST_VIEW_ADMIN,
                component: AdminTablePage
            },
            {
                exact: true,
                path: pathMap.ACCESS_LEVEL_USER_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.ACCESS_LEVEL_USER_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.ACCESS_LEVEL_USER_DETAILS_VIEW,
                component: ViewPage
            }
        ]
    }
}

export default AccessLevelUserAdminRoute
