import {
    MainPage
} from 'src/views/ping-admin/cg-migration-log'
import pathMap from 'src/paths/PathMap_Ping'

class PingCgMigrationLogRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.PING_ADMIN_CG_MIGRATION_LOG,
				component: MainPage
			}
		]
	}
}

export default PingCgMigrationLogRoute
