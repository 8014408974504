import {
    ViewPage,
} from 'src/views/ecommerce/report'
import pathMap from "src/paths/PathMap_CeCAdmin";

export default class ReportRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.CEC_REPORT,
                component: ViewPage
            }
        ]
    }
}