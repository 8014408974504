import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import { AddPage, AdminTablePage, AdminViewPage, EditPage, TablePage, UploadSkuPage, ViewPage, ConfirmSkuDetailsPage } from 'src/views/sco-invmgmt/sku/index'

class InvSkuRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_SKU_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SKU_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SKU_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SKU_CREATE_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_SKU_LIST,
                component: AdminTablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_SKU_DETAILS_VIEW,
                component: AdminViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SKU_UPLOAD,
                component: UploadSkuPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SKU_CONFIRM_UPLOAD_DETAILS,
                component: ConfirmSkuDetailsPage
            },
        ]
    }
}

export default InvSkuRoute