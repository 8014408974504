import {
    TablePage,
    EditPage,
} from 'src/views/report/downloadreport'
import pathMap from "src/paths/PathMap_Report";

export default class ReportDownloadRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.REPORT_DOWNLOAD_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.REPORT_DOWNLOAD_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}