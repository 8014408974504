import { WarehouseTrackingEnquiryPage, WarehouseTrackingRequestPage } from 'src/views/forwardhub/warehousetracking'

import pathMap from 'src/paths/PathMap_ForwardHub'

class WarehouseTrackingRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.WAREHOUSETRACKING_SEARCH_HOME,
        component: WarehouseTrackingEnquiryPage
      },
      {
        exact: true,
        path: pathMap.WAREHOUSETRACKING_REQUEST_HOME,
        component: WarehouseTrackingRequestPage
      }
    ]
  }
}

export default WarehouseTrackingRoute
