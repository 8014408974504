import { TablePage, AddPage, ViewPage, EditPage } from 'src/views/scojob/docconversion/index'
import pathMap from 'src/paths/PathMap_SCO_Job'

class SCOMyJobRoute {
      getConfigurations() {
            return [
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_DOC_CONVERSION,
                        component: TablePage
                  },
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_DOC_CONVERSION_ADD,
                        component: AddPage
                  },
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_DOC_CONVERSION_VIEW,
                        component: ViewPage
                  },
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_DOC_CONVERSION_EDIT,
                        component: EditPage
                  }

            ]
      }
}

export default SCOMyJobRoute