import {
    TablePage
} from 'src/views/report/generatedreportcustomer'
import pathMap from "src/paths/PathMap_Report";

export default class GeneratedReportCustomerRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.GENERATED_REPORT_CUSTOMER_LIST_VIEW,
                component: TablePage
            }
        ]
    }
}