import { TimezoneUserPrefPage } from 'src/views/user-preference/timezone'
import pathMap from 'src/paths/PathMap_UserPreference'

class TimezoneUserPrefRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TZ_USER_PREF_SETTING,
        component: TimezoneUserPrefPage
      },
    ]
  }
}

export default TimezoneUserPrefRoute
