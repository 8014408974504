import { ViewPage, EditPage, AddPage, TablePage } from 'src/views/sco-invmgmt/warehouse/index'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'

import { AdminTable, AdminViewPage } from 'src/views/sco-invmgmt/warehouseadmin/index'


class InvWarehouseRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_WAREHOUSE_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_WAREHOUSE_CREATE_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_WAREHOUSE_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_WAREHOUSE_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_WAREHOUSE_LIST,
                component: AdminTable
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_DETAILS_VIEW,
                component: AdminViewPage
            }

        ]
    }
}

export default InvWarehouseRoute