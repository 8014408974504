import { MyBookingPage, MyBookingReviewDraftPage, MyBookingAmendPage, MyBookingAmendReviewPage, MyBookingAmendSubmittedPage } from 'src/views/freightbooking/mybooking'


import pathMap from 'src/paths/PathMap_FreightBooking'

class FreightBookingRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.MY_BOOKING,
                component: MyBookingPage
            },
            {
                exact: true,
                path: pathMap.MY_BOOKING_REVIEW_DRAFT,
                component: MyBookingReviewDraftPage
            },
            {
                exact: true,
                path: pathMap.MY_BOOKING_AMEND,
                component: MyBookingAmendPage
            },
            {
                exact: true,
                path: pathMap.MY_BOOKING_AMEND_REVIEW,
                component: MyBookingAmendReviewPage
            },
            {
                exact: true,
                path: pathMap.MY_BOOKING_AMEND_SUBMITTED,
                component: MyBookingAmendSubmittedPage
            }
        ]
    }
}

export default FreightBookingRoute