import {
  TablePage
} from 'src/views/logisticsadmin/billingaudit'
import pathMap from 'src/paths/PathMap_LogisticsAdmin'

class BillingAuditRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.BILLING_AUDIT_LIST_VIEW,
        component: TablePage
      }
    ]
  }
}

export default BillingAuditRoute
