import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
} from 'src/views/cec-admin/connector-config/apps'
import pathMap from "src/paths/PathMap_CeCAdmin";

export default class ConnectorAppConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.CONNECTOR_APPS_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CONNECTOR_APPS_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.CONNECTOR_APPS_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.CONNECTOR_APPS_DETAILS_VIEW,
                component: ViewPage
            }
        ]
    }
}