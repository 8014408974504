import BookFromScheduleRoute from './BookFromScheduleRoute'
import BookingSyncLogRoute from './BookingSyncLogRoute'
import CreateBookingRoute from './CreateBookingRoute'
import ManageBookingRoute from './ManageBookingRoute'
import MyBookingRoute from './MyBookingRoute'
import ViewOnlyBookingRoute from './ViewOnlyBookingRoute'

const routes = [
    new CreateBookingRoute(),
    new MyBookingRoute(),
    new ManageBookingRoute(),
    new BookFromScheduleRoute(),
    new BookingSyncLogRoute(),
    new ViewOnlyBookingRoute()
]

export default routes
