import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
} from 'src/views/cec-admin/shipment-providers/shipment-provider'
import pathMap from "src/paths/PathMap_CeCAdmin";

export default class ShipmentProviderRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SHIPMENT_PROVIDER_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SHIPMENT_PROVIDER_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SHIPMENT_PROVIDER_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SHIPMENT_PROVIDER_DETAILS_VIEW,
                component: ViewPage
            }
        ]
    }
}