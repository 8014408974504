import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/tmp-admin/code-master'
import pathMap from 'src/paths/PathMap_TmpAdmin'

export default class TmpCodeMasterRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.CODE_MASTER_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CODE_MASTER_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.CODE_MASTER_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.CODE_MASTER_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}
