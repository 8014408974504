import { AddPage, EditPage, ViewPage } from 'src/views/cec-admin/connector-config/mappings'
import pathMap from 'src/paths/PathMap_CeCAdmin'

export default class ConnectorMappingRoute {
  getConfigurations() {
    return [

      {
        exact: true,
        path: pathMap.CONNECTOR_MAPPING_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.CONNECTOR_MAPPING_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.CONNECTOR_MAPPING_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}