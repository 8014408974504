import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/notification/notificationsubscription'
import pathMap from 'src/paths/PathMap_Notification'

class NotificationSubscriptionRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.NOTIFICATION_SUBSCRIPTION_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.NOTIFICATION_SUBSCRIPTION_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.NOTIFICATION_SUBSCRIPTION_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.NOTIFICATION_SUBSCRIPTION_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default NotificationSubscriptionRoute
