import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
} from 'src/views/account-setup/nbs/billing'
import pathMap from 'src/paths/PathMap_AccountSetup'

class NBSBillingConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.NBS_BILLING_CONFIG_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.NBS_BILLING_CONFIG_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.NBS_BILLING_CONFIG_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.NBS_BILLING_CONFIG_DETAILS_VIEW,
                component: ViewPage
            }
        ]
    }
}

export default NBSBillingConfigRoute
