import {
  BasePage,
  FtaProductDetailsPage,
  ViewFtaProductDetailsPage,
  FtaActivityHistoryPage
} from 'src/views/trademanagement/ftacalculator'
import { MyProductsPage, ProductPage } from 'src/views/trademanagement/myproducts'
import pathMap from 'src/paths/PathMap_TradeManagement'

class FTACalculatorRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TRADE_MGMT_FTA,
        component: BasePage
      },
      {
        exact: true,
        path: pathMap.TRADE_MGMT_PRODUCT_DETAILS,
        component: FtaProductDetailsPage
      },
      {
        exact: true,
        path: pathMap.TRADE_MGMT_VIEW_FTA_PRODUCT_DETAILS,
        component: ViewFtaProductDetailsPage
      },
      {
        exact: true,
        path: pathMap.MY_PRODUCT_FTA,
        component: MyProductsPage,
      },
      {
        exact: true,
        path: `${pathMap.MY_PRODUCT_FTA}/new`,
        component: ProductPage,
      },
      {
        exact: true,
        path: `${pathMap.MY_PRODUCT_FTA}/:productId`,
        component: ProductPage,
      },
      {
        exact: true,
        path: pathMap.TRADE_MGMT_FTA_ACTIVITY_HISTORY,
        component: FtaActivityHistoryPage
      }
    ]
  }
}

export default FTACalculatorRoute
