import TradeWizardRoute from './TradeWizardRoute'
import LandedCostRoute from './LandedCostRoute'
import MassRecommendRoute from './MassRecommendRoute'

const routes = [
  new TradeWizardRoute(),
  new LandedCostRoute(),
  new MassRecommendRoute()
]

export default routes
