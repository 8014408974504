import {
  TablePage,
  // AddPage,
  EditPage,
  ViewPage,
} from 'src/views/edi/transactionlog'
import pathMap from 'src/paths/PathMap_EDI'

class TransactionLogRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TRANSACTION_LOG_LIST_VIEW,
        component: TablePage
      },
      // {
      //   exact: true,
      //   path: pathMap.TRANSACTION_LOG_ADD_VIEW,
      //   component: AddPage
      // },
      {
        exact: true,
        path: pathMap.TRANSACTION_LOG_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.TRANSACTION_LOG_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default TransactionLogRoute
