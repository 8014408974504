import MicrositePublishedView from 'src/views/microsite/MicrositePublishedView'
import MicrositeDemo from 'src/views/microsite/MicrositeDemo'
import pathMap from 'src/paths/pathMap'

class MicrositeRoute {
      getConfigurations() {
            return [
                  {
                        exact: true,
                        path: pathMap.PARTY_MICROSITE_VIEW,
                        component: MicrositePublishedView
                  },
                  {
                        exact: true,
                        path: pathMap.PARTY_MICROSITE_DEMO,
                        component: MicrositeDemo
                  }
            ]
      }
}

export default MicrositeRoute
