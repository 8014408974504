import { TablePage, ViewPage} from 'src/views/sco-admin/CountryStateCode'
import pathMap from 'src/paths/pathMap'


class SCOAdminCountryStateCodeRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_ADMIN_STATE_CODE_COUNTRY_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_ADMIN_STATE_CODE_STATE_LIST_VIEW,
                component: ViewPage
            }
        ]
    }
}

export default SCOAdminCountryStateCodeRoute