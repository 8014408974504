import {
	TablePage,
	ViewPage,
	EditPage
} from 'src/views/ping-admin/system-config'
import pathMap from 'src/paths/PathMap_Ping'

class PingSysConfigRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.PING_ADMIN_SYS_CONFIG_LIST_VIEW,
				component: TablePage
			},
			{
				exact: true,
				path: pathMap.PING_ADMIN_SYS_CONFIG_DETAILS_VIEW,
				component: ViewPage
			},
			{
				exact: true,
				path: pathMap.PING_ADMIN_SYS_CONFIG_EDIT_VIEW,
				component: EditPage
			}
		]
	}
}

export default PingSysConfigRoute
