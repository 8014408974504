import { ManagePreferredCarrierTablePage } from 'src/views/vesselschedule/managepreferredcarrier'
import pathMap from 'src/paths/PathMap_VesselSchedule'

class ManagePreferredCarrierRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_PREF_CAR_LIST_VIEW,
        component: ManagePreferredCarrierTablePage
      }
    ]
  }
}

export default ManagePreferredCarrierRoute
