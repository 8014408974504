import { MyEvgmPage } from 'src/views/evgm'

import pathMap from 'src/paths/PathMap_Evgm'


class MyEvgmRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.MY_EVGM,
                component: MyEvgmPage
            }
        ]
    }
}

export default MyEvgmRoute