import { UploadPage } from 'src/views/tmp-admin/mass-upload'
import pathMap from 'src/paths/PathMap_TmpAdmin'

export default class TmpMassUploadRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.MASS_UPLOAD,
                component: UploadPage
            }
        ]
    }
}
