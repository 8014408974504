import { TablePage, ViewPage, EditPage } from 'src/views/sco-invmgmt/stock/index'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'

class InvStockInventoryRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_STOCK_INVENTORY_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_STOCK_INVENTORY_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_STOCK_INVENTORY_DETAILS_EDIT,
                component: EditPage
            }
        ]
    }
}

export default InvStockInventoryRoute