import { TablePage, ViewPage, EditPage, AddPage, AdminTablePage, AdminViewPage } from 'src/views/sco-invmgmt/buyer/index'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'


class InvBuyerRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_BUYER_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_BUYER_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_BUYER_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_BUYER_CREATE_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_BUYER_LIST,
                component: AdminTablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_BUYER_DETAILS_VIEW,
                component: AdminViewPage
            },
        ]
    }
}

export default InvBuyerRoute