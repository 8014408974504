import {
	TablePage,
	ViewPage,
	EditPage,
	AddPage
} from 'src/views/ping-admin/shipment-event'
import pathMap from 'src/paths/PathMap_Ping'

class PingShipmentEventRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.PING_ADMIN_SHIPMENT_EVENT_LIST_VIEW,
				component: TablePage
			},
			{
				exact: true,
				path: pathMap.PING_ADMIN_SHIPMENT_EVENT_DETAILS_VIEW,
				component: ViewPage
			},
			{
				exact: true,
				path: pathMap.PING_ADMIN_SHIPMENT_EVENT_EDIT_VIEW,
				component: EditPage
			},
			{
				exact: true,
				path: pathMap.PING_ADMIN_SHIPMENT_EVENT_ADD_VIEW,
				component: AddPage
			}
		]
	}
}

export default PingShipmentEventRoute
