import InvBuyerRoute from './InvBuyerRoute'
import InvSupplierRoute from './InvSupplierRoute'
import InvCategoryRoute from './InvCategoryRoute'
import InvWarehouseRoute from './InvWarehouseRoute'
import InvNonStorageRoute from './InvNonStorageRoute'
import InvSkuRoute from './InvSkuRoute'
import InvDefLocConfigRoute from './InvDefLocConfigRoute'
import InvStockInventoryRoute from './InvStockInventoryRoute'
import InvPrincipalMappingRoute from './InvPrincipalMappingRoute'
import InvReportRoute from "./InvReportRoute";

const routes = [
    new InvSupplierRoute(),
    new InvBuyerRoute(),
    new InvCategoryRoute(),
    new InvWarehouseRoute(),
    new InvNonStorageRoute(),
    new InvSkuRoute(),
    new InvDefLocConfigRoute(),
    new InvStockInventoryRoute(),
    new InvPrincipalMappingRoute(),
    new InvReportRoute()
]

export default routes