import {
	TablePage, 
	EditPage,
	ViewPage
} from 'src/views/calista-party-company-admin'
import pathMap from 'src/paths/PathMap_CalistaPartyCompanyAdmin'
import MicrositePreview from 'src/views/microsite/MicrositePreview'

class CalistaCompanyAdminRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_ADMIN_LIST,
				component: TablePage
			},
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_ADMIN_VIEW,
				component: ViewPage
			},
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_ADMIN_EDIT,
				component: EditPage
			},
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_ADMIN_MICROSITE_PREVIEW,
				component: MicrositePreview
			}

		]
	}
}

export default CalistaCompanyAdminRoute;
