import {
  CarrierResultPage,
  CarrierSearchPage
} from 'src/views/vesselschedule/searchschedule/carrierSearch/'
import { ResultPage, SearchPage } from 'src/views/vesselschedule/searchschedule'

import pathMap from 'src/paths/PathMap_VesselSchedule'

class SearchScheduleRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.SEARCH_SCHEDULE_VIEW,
        component: SearchPage
      },
      {
        exact: true,
        path: pathMap.SEARCH_SCHEDULE_RESULT,
        component: ResultPage
      },
      {
        exact: true,
        path: pathMap.CARRIER_SEARCH_SCHEDULE,
        component: CarrierSearchPage
      },
      {
        exact: true,
        path: pathMap.CARRIER_SEARCH_SCHEDULE_RESULT,
        component: CarrierResultPage
      }
    ]
  }
}

export default SearchScheduleRoute
