import { TablePage, AddPage, EditPage } from 'src/views/scojob/job/index'
import pathMap from 'src/paths/PathMap_SCO_Job'

class SCOMyJobRoute {
      getConfigurations() {
            return [
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_LIST_VIEW,
                        component: TablePage
                  },
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_ADD_VIEW,
                        component: AddPage
                  },
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_DETAILS_EDIT_VIEW,
                        component: EditPage
                  }
            ]
      }
}

export default SCOMyJobRoute