import CarrierEnrollRoute from './CarrierEnrollRoute'
import ManageBlacklistedCarriersRoute from './ManageBlacklistedCarriersRoute'
import ManagePreferredCarrierRoute from './ManagePreferredCarrierRoute'
import ManagePreferredPortPairsRoute from './ManagePreferredPortPairsRoute'
import PollingRoute from './PollingRoute'
import SearchScheduleRoute from './SearchScheduleRoute'
import TcalVsManageScheduleRoute from './TcalVsManageScheduleRoute'
import TcalVsVesselRoute from './TcalVsVesselRoute'
import TcalVsVoyageRoute from './TcalVsVoyageRoute'
import VsMigrationSyncLogRoute from './VsMigrationSyncLogRoute'
import CodeMappingRoute from './CodeMappingRoute'

const routes = [
    new CarrierEnrollRoute(),
    new ManageBlacklistedCarriersRoute(),
    new ManagePreferredCarrierRoute(),
    new ManagePreferredPortPairsRoute(),
    new PollingRoute(),
    new SearchScheduleRoute(),
    new TcalVsVesselRoute(),
    new TcalVsVoyageRoute(),
    new TcalVsManageScheduleRoute(),
    new VsMigrationSyncLogRoute(),
    new CodeMappingRoute()
]

export default routes
