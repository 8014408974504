import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import { TablePage, AddPage, EditPage, ViewPage } from 'src/views/sco-invmgmt/nonstorage/index'
import {AdminTable, AdminViewPage} from 'src/views/sco-invmgmt/nonstorageadmin/index'
class InvNonStorageRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_NONSTORAGE_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_NONSTORAGE_CREATE_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_NONSTORAGE_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_NONSTORAGE_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_NONSTORAGE_LIST,
                component: AdminTable
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_NONSTORAGE_DETAILS_VIEW,
                component: AdminViewPage
            }

        ]
    }
}

export default InvNonStorageRoute