import {
	PingSearch_BookingList,
	PingSearch_FavouriteList,
	PingSearch_HistoryList,
	PingSearch_BookingSearch,
	PingSearch_ContainerSearch,
	PingSearch_BLNumSearch,
	PingSearch_VesselSearch,
	PingSearch_EventSubscription
} from 'src/views/ping/search'
import pathMap from 'src/paths/PathMap_Ping'

class PingSearchRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.PING_SEARCH,
				component: PingSearch_BookingList
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_BOOKING_LIST,
				component: PingSearch_BookingList
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_FAVOURITE_LIST,
				component: PingSearch_FavouriteList
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_HISTORY_LIST,
				component: PingSearch_HistoryList
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_BOOKING_REF,
				component: PingSearch_BookingSearch
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_CONTAINER_NUM,
				component: PingSearch_ContainerSearch
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_BL_NUM,
				component: PingSearch_BLNumSearch
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_VESSEL_NAME,
				component: PingSearch_VesselSearch
			},
			{
				exact: true,
				path: pathMap.PING_SEARCH_EVENT_SOURCE,
				component: PingSearch_EventSubscription
			}
		]
	}
}

export default PingSearchRoute
