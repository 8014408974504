import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/edi/batchdataconfig'
import pathMap from 'src/paths/PathMap_EDI'

class BatchDataConfigRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.BATCH_DATA_CONFIG_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.BATCH_DATA_CONFIG_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.BATCH_DATA_CONFIG_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.BATCH_DATA_CONFIG_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default BatchDataConfigRoute
