import { AddPage, EditPage, TablePage, ViewPage } from 'src/views/ecommerce/delivery-order'
import pathMap from 'src/paths/PathMap_eCommerce'

export default class DeliveryOrderRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CEC_DELIVERY_ORDER_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.CEC_DELIVERY_ORDER_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.CEC_DELIVERY_ORDER_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.CEC_DELIVERY_ORDER_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}
