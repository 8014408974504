import {
  AddPage,
  EditPage,
  TablePage,
  ViewPage
} from 'src/views/ricestock/importer'
import {
  ImportStatusByImporterDashboard,
  ImportStatusDashboard,
  ImporterProfileDashboard,
  StockpileDashboard,
  UploadImporterPage,
  UploadPOPage,
  UploadRiceStockConfigurationPage,
  UploadStockDetailPage
} from 'src/views/ricestock'

import pathMap from 'src/paths/PathMap_RiceStock'

export default class RiceStockRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.UPLOAD_IMPORTER_PROFILE_VIEW,
        component: UploadImporterPage
      },
      {
        exact: true,
        path: pathMap.UPLOAD_PO_DETAIL_VIEW,
        component: UploadPOPage
      },
      {
        exact: true,
        path: pathMap.UPLOAD_STOCK_DETAIL_VIEW,
        component: UploadStockDetailPage
      },
      {
        exact: true,
        path: pathMap.POWERBI_DASHBOARD_IMPORTER_PROFILE_VIEW,
        component: ImporterProfileDashboard
      },
      {
        exact: true,
        path: pathMap.POWERBI_DASHBOARD_IMPORT_STATUS_BY_IMPORTER_VIEW,
        component: ImportStatusByImporterDashboard
      },
      {
        exact: true,
        path: pathMap.POWERBI_DASHBOARD_IMPORT_STATUS_VIEW,
        component: ImportStatusDashboard
      },
      {
        exact: true,
        path: pathMap.POWERBI_DASHBOARD_STOCKPILE_VIEW,
        component: StockpileDashboard
      },
      {
        exact: true,
        path: pathMap.UPLOAD_RICE_STOCKPILE_CONFIG_VIEW,
        component: UploadRiceStockConfigurationPage
      },
      {
        exact: true,
        path: pathMap.IMPORTERS_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.IMPORTERS_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.IMPORTERS_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.IMPORTERS_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}
