import {
  ViewBookingDetailsPage,
  ViewBookingPage
} from 'src/views/helpdesk/viewbooking'
import {
  ViewSIDetailsPage,
  ViewSIPage
} from 'src/views/helpdesk/viewsi'
import {
  TablePage
} from 'src/views/helpdesk/searchsi'

import pathMap from 'src/paths/PathMap_Helpdesk'

class HelpdeskRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.VIEW_BOOKING,
        component: ViewBookingPage
      },
      {
        exact: true,
        path: pathMap.VIEW_BOOKING_DETAILS,
        component: ViewBookingDetailsPage
      }
      ,
      {
        exact: true,
        path: pathMap.TEST,
        component: ViewBookingPage
      }
      ,
      {
        exact: true,
        path: pathMap.FRB,
        component: ViewBookingPage
      },
      {
        exact: true,
        path: pathMap.FRB_DETAILS,
        component: ViewBookingDetailsPage
      },
      {
        exact: true,
        path: pathMap.SI,
        component: ViewSIPage
      },
      {
        exact: true,
        path: pathMap.SI_DETAILS,
        component: ViewSIDetailsPage
      },
      {
        exact: true,
        path: pathMap.SEARCH_SI,
        component: TablePage
      },
    ]
  }
}

export default HelpdeskRoute
