import { ManagePreferredPortPairsTablePage } from 'src/views/vesselschedule/managepreferredportpairs'
import pathMap from 'src/paths/PathMap_VesselSchedule'

class ManagePreferredPortPairsRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_PREF_PP_LIST_VIEW,
        component: ManagePreferredPortPairsTablePage
      }
    ]
  }
}

export default ManagePreferredPortPairsRoute
