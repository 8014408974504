import { AcctMgmtPage } from 'src/views/cia-company-admin'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class AcctMgmtRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CALISTA_ACCOUNT_MANAGEMENT,
        component: AcctMgmtPage
      }
    ]
  }
}

export default AcctMgmtRoute
