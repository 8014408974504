import { DashboardBasicPage, DashboardEnhancedPage } from 'src/views/forwardhub/dashboard'

import pathMap from 'src/paths/PathMap_ForwardHub'

class DashboardRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.DASHBOARD_BASIC_HOME,
        component: DashboardBasicPage
      },
      {
        exact: true,
        path: pathMap.DASHBOARD_ENHANCED_HOME,
        component: DashboardEnhancedPage
      }
    ]
  }
}

export default DashboardRoute
