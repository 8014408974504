import { ViewOnlyBookingPage, ViewOnlyBookingReviewPage } from 'src/views/freightbooking/viewonlybooking'


import pathMap from 'src/paths/PathMap_FreightBooking'

class FreightBookingRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.VIEW_ONLY_BOOKING,
                component: ViewOnlyBookingPage
            },
            {
                exact: true,
                path: pathMap.VIEW_ONLY_BOOKING_REVIEW,
                component: ViewOnlyBookingReviewPage
            }
        ]
    }
}

export default FreightBookingRoute