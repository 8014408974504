import {
    UploadPage
} from 'src/views/ecommerce/upload'
import pathMap from "src/paths/PathMap_EcommerceUpload";

export default class EcommerceUploadRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.UPLOAD_PAGE,
                component: UploadPage
            }
        ]
    }
}