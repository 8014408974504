import {
    ManageBookingAmendPage,
    ManageBookingAmendReviewPage,
    ManageBookingAmendSubmittedPage,
    ManageBookingPage,
    ManageBookingReviewPage
} from 'src/views/freightbooking/managebooking'

import pathMap from 'src/paths/PathMap_FreightBooking'

class FreightBookingRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.MANAGE_BOOKING,
                component: ManageBookingPage
            },
            {
                exact: true,
                path: pathMap.MANAGE_BOOKING_REVIEW,
                component: ManageBookingReviewPage
            },
            {
                exact: true,
                path: pathMap.MANAGE_BOOKING_AMEND,
                component: ManageBookingAmendPage
            },
            {
                exact: true,
                path: pathMap.MANAGE_BOOKING_AMEND_REVIEW,
                component: ManageBookingAmendReviewPage
            },
            {
                exact: true,
                path: pathMap.MANAGE_BOOKING_AMEND_SUBMITTED,
                component: ManageBookingAmendSubmittedPage
            }
        ]
    }
}

export default FreightBookingRoute