import PingSysConfigRoute from './PingSysConfigRoute'
import PingPartyConfigRoute from './PingPartyConfigRoute'
import PingEventSourceRoute from './PingEventSourceRoute'
import PingEventCodeRoute from './PingEventCodeRoute'
import PingEventCodeMappingRoute from './PingEventCodeMappingRoute'
import PingEventCodeLinkRoute from './PingEventCodeLinkRoute'
import PingShipmentEventRoute from './PingShipmentEventRoute'
import PingSearchLogRoute from './PingSearchLogRoute'
import PingVesselVoyageInfoRoute from './PingVesselVoyageInfoRoute'
import PingEventHubApiLogsRoute from './PingEventHubApiLogsRoute'
import PingPortnetApiLogsRoute from './PingPortnetApiLogsRoute'
import PingCgMigrationLogRoute from './PingCgMigrationLogRoute'

const routes = [
    new PingSysConfigRoute(),
    new PingPartyConfigRoute(),
    new PingEventSourceRoute(),
    new PingEventCodeRoute(),
    new PingEventCodeMappingRoute(),
    new PingEventCodeLinkRoute(),
    new PingShipmentEventRoute(),
    new PingSearchLogRoute(),
    new PingVesselVoyageInfoRoute(),
    new PingEventHubApiLogsRoute(),
    new PingPortnetApiLogsRoute(),
    new PingCgMigrationLogRoute()
]

export default routes
