import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/account-setup/accesslevelcompanycustomrule'
import pathMap from 'src/paths/PathMap_AccountSetup'

class AccessLevelCompanyCustomRuleRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default AccessLevelCompanyCustomRuleRoute
