import { AddressBookShipperPage, AddressBookCarrierPage } from 'src/views/company-preference/addressbook'
import pathMap from 'src/paths/PathMap_CompanyPreference'

class AddressBookRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ADDRESS_BOOK_SHIPPER,
        component: AddressBookShipperPage
      },
      {
        exact: true,
        path: pathMap.ADDRESS_BOOK_CARRIER,
        component: AddressBookCarrierPage
      },
    ]
  }
}

export default AddressBookRoute
