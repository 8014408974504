import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/tmp-admin/supplier'
import pathMap from 'src/paths/PathMap_TmpAdmin'

export default class TmpSupplierRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.SUPPLIER_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SUPPLIER_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SUPPLIER_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SUPPLIER_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}
