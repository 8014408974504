import {
	TablePage,
	ViewPage,
	EditPage
} from 'src/views/calista-admin/calista-party-config'
import pathMap from 'src/paths/PathMap_CalistaAdmin'
import MicrositePreview from 'src/views/microsite/MicrositePreview'

class CalistaPartyConfigRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_CONFIG_LIST_VIEW,
				component: TablePage
			},
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_CONFIG_DETAILS_VIEW,
				component: ViewPage
			},
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_CONFIG_EDIT_VIEW,
				component: EditPage
			},
			{
				exact: true,
				path: pathMap.CALISTA_PARTY_CONFIG_MICROSITE_PREVIEW,
				component: MicrositePreview
			}
		]
	}
}

export default CalistaPartyConfigRoute
