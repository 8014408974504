import {
    TablePage,
    ViewPage,
    EditPage
} from 'src/views/ping-admin/party-config'
import pathMap from 'src/paths/PathMap_Ping'

class PingPartyConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.PING_ADMIN_PARTY_CONFIG_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.PING_ADMIN_PARTY_CONFIG_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.PING_ADMIN_PARTY_CONFIG_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}

export default PingPartyConfigRoute
