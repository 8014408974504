import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/cec-account/Account'
import pathMap from 'src/paths/pathMap'


class CecAccountRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.CEC_ACCOUNT_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CEC_ACCOUNT_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.CEC_ACCOUNT_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.CEC_ACCOUNT_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}

export default CecAccountRoute