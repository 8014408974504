import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/tcalvsvoyage'
import pathMap from 'src/paths/PathMap_VesselSchedule'

class TcalVsVoyageRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TCAL_VS_VOYAGE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_VOYAGE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_VOYAGE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_VOYAGE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default TcalVsVoyageRoute
