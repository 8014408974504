import pathMap from "src/paths/PathMap_TmpAdmin";
import { TablePage} from "src/views/tmp-admin/country-map";

export default class TmpCountryMapRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.COUNTRY_MAPPING_LIST_VIEW,
                component: TablePage
            }
        ]
    }
}