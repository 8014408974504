import { TablePage, EditPage } from 'src/views/compliance/declarantmaintenance'
import pathMap from 'src/paths/PathMap_Compliance'



class DeclarantMaintenanceRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.DECLARANT_MAINTENANCE_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.DECLARANT_MAINTENANCE_EDIT_VIEW,
                component: EditPage
            }

        ]
    }
}

export default DeclarantMaintenanceRoute