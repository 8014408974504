import { AddPage } from 'src/views/sco-invmgmt/report'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'


class InvWarehouseRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_REPORT,
                component: AddPage
            },

        ]
    }
}

export default InvWarehouseRoute