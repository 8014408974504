import pathMap from 'src/paths/PathMap_TradeManagement'
import {SearchPage} from '../../views/trademanagement/deniedparytsearch'


export default class TmpDpsRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.DPS_SEARCH_PAGE,
                component: SearchPage
            }
        ]
    }
}
