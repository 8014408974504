import { EditPage} from 'src/views/freightbooking/companypreference'
import pathMap from 'src/paths/PathMap_CompanyPreference'

class BookingPrefRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.FRB_COMPANY_PREFERENCE_EDIT_VIEW,
        component: EditPage
      },
    ]
  }
}

export default BookingPrefRoute
