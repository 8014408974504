import pathMap from 'src/paths/PathMap_CarbonFootprint'
import MethodologyPage from '../../views/carbon-footprint-calculator/methodology/MethodologyPage'

class EcoCalculatorRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.METHODOLOGY_PATH,
        component: MethodologyPage
      }
    ]
  }
}
export default EcoCalculatorRoute
