import { TablePage} from 'src/views/sco-admin/TimeZone'
import pathMap from 'src/paths/pathMap'


class SCOAdminTimeZoneRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_ADMIN_TIMEZONE_LIST_VIEW,
                component: TablePage
            }
        ]
    }
}

export default SCOAdminTimeZoneRoute