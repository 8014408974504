import { TablePage, AddPage, EditPage } from 'src/views/scojob/jobattribute/index'
import pathMap from 'src/paths/PathMap_SCO_JobAttribute'


class SCOJobAttributeRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_JOB_ATTRIBUTE_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_JOB_ATTRIBUTE_CREATE_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_JOB_ATTRIBUTE_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}

export default SCOJobAttributeRoute