import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/tmp-admin/component'
import pathMap from 'src/paths/PathMap_TmpAdmin'

export default class TmpCodeMasterRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.COMPONENT_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.COMPONENT_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.COMPONENT_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.COMPONENT_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}
