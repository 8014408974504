import {
	MainPage
} from 'src/views/ping-admin/eventhub-logs'
import pathMap from 'src/paths/PathMap_Ping'

class PingEventHubApiLogsRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.PING_ADMIN_EVENTHUB_LOG,
				component: MainPage
			}
		]
	}
}

export default PingEventHubApiLogsRoute
