import { TablePage } from 'src/views/scojob/jobrequest/index'
import { AddPage } from 'src/views/scojob/job/index'
import pathMap from 'src/paths/PathMap_SCO_Job'

class SCOJobRequestRoute {
      getConfigurations() {
            return [
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_REQUEST,
                        component: TablePage
                  },
                  {
                        exact: true,
                        path: pathMap.SCO_JOB_ADD_REQ,
                        component: AddPage
                  }
            ]
      }
}

export default SCOJobRequestRoute