import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/vesselschedule/polling'
import pathMap from 'src/paths/PathMap_VesselSchedule'

class PollingRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.POLLING_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.POLLING_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.POLLING_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.POLLING_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default PollingRoute
