import {
    TablePage,
    EditPage,
    ViewPage,
} from 'src/views/cec-admin/party-config'
import pathMap from "src/paths/PathMap_CeCAdmin";

export default class PartyConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.CEC_ADMIN_PARTY_CONFIG_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CEC_ADMIN_PARTY_CONFIG_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.CEC_ADMIN_PARTY_CONFIG_DETAILS_VIEW,
                component: ViewPage
            }
        ]
    }
}