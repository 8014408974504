import pathMap from 'src/paths/PathMap_ShippingInstruction'
import { ViewOnlySIPage, ViewOnlySIViewSIPage } from 'src/views/shippinginstruction/viewsi'

class ShippingInstrutionRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.VIEW_ONLY_SI,
        component: ViewOnlySIPage
      },
      {
        exact: true,
        path: pathMap.VIEW_ONLY_SI_VIEW_SI,
        component: ViewOnlySIViewSIPage
      }
    ]
  }
}

export default ShippingInstrutionRoute
