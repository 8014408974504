import pathMap from "src/paths/PathMap_TmpAdmin";
import { TablePage, EditPage, ViewPage } from "src/views/tmp-admin/system-config";

export default class TmpSysConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SYS_CONFIG_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SYS_CONFIG_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SYS_CONFIG_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}