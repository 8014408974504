import {
  AddScheduleDetailsPage,
  AddScheduleFileUploadFinishPage,
  AddScheduleFileUploadPage,
  AddScheduleFinishPage,
  AddSchedulePage,
  AmendSchedulePage,
  AmendScheduleDetailsPage,
  AmendScheduleFinishPage,
  ManageScheduleTablePage,
  ViewSchedulePage,
  ViewUploadScheduleTablePage
} from 'src/views/vesselschedule/manageschedule'

import pathMap from 'src/paths/PathMap_VesselSchedule'

class TcalVsManageScheduleRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_LIST,
        component: ManageScheduleTablePage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD,
        component: AddSchedulePage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_DETAILS,
        component: AddScheduleDetailsPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FINISH,
        component: AddScheduleFinishPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW,
        component: ViewSchedulePage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND,
        component: AmendSchedulePage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND_DETAILS,
        component: AmendScheduleDetailsPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_AMEND_FINISH,
        component: AmendScheduleFinishPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_VIEW_UPLOAD_SCHEDULE,
        component: ViewUploadScheduleTablePage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD,
        component: AddScheduleFileUploadPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_SCHEDULE_ADD_FILE_UPLOAD_FINISH,
        component: AddScheduleFileUploadFinishPage
      }
    ]
  }
}

export default TcalVsManageScheduleRoute
