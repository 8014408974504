import {
    MainPage
} from 'src/views/ping-admin/portnet-logs'
import pathMap from 'src/paths/PathMap_Ping'

class PingPortnetApiLogsRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.PING_ADMIN_PORTNET_LOG,
                component: MainPage
            }
        ]
    }
}

export default PingPortnetApiLogsRoute
