import {
    TablePage,
    AddPage,
    EditPage
} from 'src/views/report/scheduler-report-customer'
import pathMap from "src/paths/PathMap_Report";

export default class ReportSchedulerCustomerRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.REPORT_SCHEDULER_CUSTOMER_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.REPORT_SCHEDULER_CUSTOMER_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.REPORT_SCHEDULER_CUSTOMER_ADD_VIEW,
                component: AddPage
            }
        ]
    }
}