import{
    CreatePermitPage,
    ReviewPermitPage,
    CreatePermitNewPermitSubmitPage
}from 'src/views/compliance'
import pathMap from 'src/paths/PathMap_Compliance'

class ComplianceRoute{
    getConfigurations() {
        return[
            {
                exact: true,
                path: pathMap.CREATE_NEW_PERMIT,
                component: CreatePermitPage
            },
            {
                exact: true,
                path: pathMap.CREATE_NEW_PERMIT_REVIEW,
                component: ReviewPermitPage
            },
            {
                exact: true,
                path: pathMap.CREATE_PERMIT_NEW_PERMIT_SUBMITTED,
                component: CreatePermitNewPermitSubmitPage
            },
        ]
    }
}

export default ComplianceRoute