import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/tmp-admin/company-profile'
import pathMap from 'src/paths/PathMap_TmpAdmin'

export default class TmpCompanyProfileRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.COMPANY_PROFILE_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.COMPANY_PROFILE_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.COMPANY_PROFILE_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.COMPANY_PROFILE_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}
