import { FTMEnquiryPage, FTMRequestPage } from 'src/views/forwardhub/ftmrequest'

import pathMap from 'src/paths/PathMap_ForwardHub'

class FTMRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.FTM_SEARCH_HOME,
        component: FTMEnquiryPage
      },
      {
        exact: true,
        path: pathMap.FTM_REQUEST_HOME,
        component: FTMRequestPage
      }
    ]
  }
}

export default FTMRoute
