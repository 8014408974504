import { QuickSearchPage } from 'src/views/intelligent-advisory'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class TradeWizardRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CALISTA_ADVISORY_WIZARD,
        component: QuickSearchPage
      }
    ]
  }
}

export default TradeWizardRoute
