import TmpCodeMasterRoute from './TmpCodeMasterRoute'
import TmpCompanyProfileRoute from './TmpCompanyProfileRoute'
import TmpSupplierRoute from './TmpSupplierRoute'
import TmpComponentRoute from './TmpComponentRoute'
import TmpMassUploadRoute from './TmpMassUploadRoute'
import TmpSysConfigRoute from './TmpSysConfigRoute'
import TmpCountryMapRoute from './TmpCountryMapRoute'
import TmpMasterUploadRoute from './TmpMasterUploadRoute'

const routes = [
    new TmpCodeMasterRoute(),
    new TmpCompanyProfileRoute(),
    new TmpSupplierRoute(),
    new TmpComponentRoute(),
    new TmpMassUploadRoute(),
    new TmpSysConfigRoute(),
    new TmpCountryMapRoute(),
    new TmpMasterUploadRoute()

]

export default routes
