import { 
    CreateEVGMPage,
    ReviewEvgmPage
 } from 'src/views/evgm'
import pathMap from 'src/paths/PathMap_Evgm'

class CreateEVGMRoute{
    getConfigurations() {
        return[
            {
                exact: true,
                path: pathMap.CREATE_NEW_EVGM,
                component: CreateEVGMPage
            },
            {
                exact: true,
                path: pathMap.REVIEW_EVGM,
                component: ReviewEvgmPage
            }
        ]
    }
}

export default CreateEVGMRoute