import { MyBookingPage, NewBookingPage, ReviewBookingPage } from 'src/views/freightbooking/newbooking'

import pathMap from 'src/paths/PathMap_FreightBooking'

class FreightBookingRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.CREATE_NEW_BOOKING,
                component: NewBookingPage
            },
            {
                exact: true,
                path: pathMap.CREATE_NEW_BOOKING_REVIEW,
                component: ReviewBookingPage
            },
            {
                exact: true,
                path: pathMap.CREATE_NEW_BOOKING_SUBMITTED,
                component: ReviewBookingPage //share review page
            },
        ]
    }
}

export default FreightBookingRoute