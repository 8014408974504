import FTACalculatorRoute from './FTACalculatorRoute'
import TmpDpsRoute from './TmpDpsRoute'


const routes = [
  new FTACalculatorRoute(),
  new TmpDpsRoute()
]

export default routes
