import {
  ManageSIPage,
  ManageSIViewSIPage,
  ManageSIManagePage,
  ManageSIReviewPage,
  ManageSISubmitPage
} from 'src/views/shippinginstruction/managesi'
import pathMap from 'src/paths/PathMap_ShippingInstruction'

class ShippingInstrutionRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.MANAGE_SI,
        component: ManageSIPage
      },
      {
        exact: true,
        path: pathMap.MANAGE_SI_VIEW_SI,
        component: ManageSIViewSIPage
      },
      {
        exact: true,
        path: pathMap.MANAGE_SI_MANAGE_SI,
        component: ManageSIManagePage
      },
      {
        exact: true,
        path: pathMap.MANAGE_SI_MANAGE_SI_REVIEW,
        component: ManageSIReviewPage
      },
      {
        exact: true,
        path: pathMap.MANAGE_SI_MANAGE_SI_SUBMITTED,
        component: ManageSISubmitPage
      }
    ]
  }
}

export default ShippingInstrutionRoute
