import PartyMappingRoute from './PartyMappingRoute'
import BatchBookingRequestRoute from './BatchBookingRequestRoute'
import CodeMappingRoute from './CodeMappingRoute'
import PortMappingRoute from './PortMappingRoute'
import PlatformPortMappingRoute from './PlatformPortMappingRoute'
import BatchDataConfigRoute from './BatchDataConfigRoute'
import TransactionLogRoute from './TransactionLogRoute'
import SinotransRegistrationRoute from './SinotransRegistrationRoute'
import BatchMilestoneRoute from './BatchMilestoneRoute'
import BatchSftpProcessRoute from './BatchSftpProcessRoute'
import ShippingInstructionRequestRoute from './ShippingInstructionRequestRoute'
import NbsMappingRoute from './NbsMappingRoute'

const routes = [
    new PartyMappingRoute(),
    new BatchBookingRequestRoute(),
    new CodeMappingRoute(),
    new PortMappingRoute(),
    new PlatformPortMappingRoute(),
    new BatchDataConfigRoute(),
    new TransactionLogRoute(),
    new SinotransRegistrationRoute(),
	new BatchMilestoneRoute(),
	new BatchSftpProcessRoute(),
    new ShippingInstructionRequestRoute(),
    new NbsMappingRoute()
]

export default routes
