import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
} from 'src/views/account-setup/nbs/file'
import pathMap from 'src/paths/PathMap_AccountSetup'

class NBSFileRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.NBS_FILE_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.NBS_FILE_DETAILS_VIEW,
                component: ViewPage
            }
        ]
    }
}

export default NBSFileRoute
