import { TablePage, AddPage, EditPage, ViewPage } from 'src/views/cec-admin/commoncitymapping'
import pathMap from 'src/paths/PathMap_CeCAdmin'

export default class CommonCityMappingRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.COMMON_CITY_MAPPING,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.COMMON_CITY_MAPPING_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.COMMON_CITY_MAPPING_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.COMMON_CITY_MAPPING_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}