import {
  CreateSIPage,
  CreateSIReviewBooking,
  CreateSIViewSIDraft,
  CreateSINewPage,
  CreateSIUpdateSIDraft,
  CreateSINewSIReviewPage,
  CreateSINewSISubmitPage
} from 'src/views/shippinginstruction/createsi'
import pathMap from 'src/paths/PathMap_ShippingInstruction'

class ShippingInstrutionRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CREATE_SI,
        component: CreateSIPage
      },
      {
        exact: true,
        path: pathMap.CREATE_SI_REVIEW_BOOKING,
        component: CreateSIReviewBooking
      },
      {
        exact: true,
        path: pathMap.CREATE_SI_VIEW_SI_DRAFT,
        component: CreateSIViewSIDraft
      },
      {
        exact: true,
        path: pathMap.CREATE_SI_NEW_SI,
        component: CreateSINewPage
      },
      {
        exact: true,
        path: pathMap.CREATE_SI_UPDATE_SI_DRAFT,
        component: CreateSIUpdateSIDraft
      },
      {
        exact: true,
        path: pathMap.CREATE_SI_NEW_SI_REVIEW,
        component: CreateSINewSIReviewPage
      },
      {
        exact: true,
        path: pathMap.CREATE_SI_NEW_SI_SUBMITTED,
        component: CreateSINewSISubmitPage
      },

      {
        exact: true,
        path: pathMap.CREATE_SI_DRAFT_REVIEW,
        component: CreateSINewSIReviewPage
      },
      {
        exact: true,
        path: pathMap.CREATE_SI_DRAFT_SUBMITTED,
        component: CreateSINewSISubmitPage
      }
    ]
  }
}

export default ShippingInstrutionRoute
