import {
	TablePage,
	ViewPage
} from 'src/views/ping-admin/search-log'
import pathMap from 'src/paths/PathMap_Ping'

class PingSearchLogRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.PING_ADMIN_SEARCH_LOG_LIST_VIEW,
				component: TablePage
			},
			{
				exact: true,
				path: pathMap.PING_ADMIN_SEARCH_LOG_DETAILS_VIEW,
				component: ViewPage
			}
		]
	}
}

export default PingSearchLogRoute
