import { TablePage, AddPage, ViewPage, EditPage } from 'src/views/scojob/jobconfig/index'
import pathMap from 'src/paths/PathMap_SCO_Job'


class SCOJobConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_JOB_CONFIG_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_JOB_CONFIG_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_JOB_CONFIG_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_JOB_CONFIG_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}

export default SCOJobConfigRoute