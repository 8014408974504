import {
    SearchPage,
    ResultPage
} from 'src/views/vesselschedule/searchschedule'

import { BookFromSchedulePage, BookFromScheduleResultPage } from 'src/views/freightbooking/bookfromschedule'
import pathMap from 'src/paths/PathMap_FreightBooking'

class FreightBookingRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.BOOK_FROM_SCHEDULE,
                component: BookFromSchedulePage
            },
            {
                exact: true,
                path: pathMap.BOOK_FROM_SCHEDULE_RESULT,
                component: BookFromScheduleResultPage
            }
        ]
    }
}

export default FreightBookingRoute