import {
    TablePage,
    AddPage,
    EditPage
} from 'src/views/report/scheduler-report-admin'
import pathMap from "src/paths/PathMap_Report";

export default class ReportSchedulerRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.REPORT_SCHEDULER_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.REPORT_SCHEDULER_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.REPORT_SCHEDULER_ADD_VIEW,
                component: AddPage
            }
        ]
    }
}