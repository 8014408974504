import SCOAdminCodeMasterRoute from './SCOAdminCodeMasterRoute'
import SCOAdminCountryCodeRoute from './SCOAdminCountryCodeRoute'
import SCOAdminCountryStateCodeRoute from './SCOAdminCountryStateCodeRoute'
import SCOAdminTimeZoneRoute from './SCOAdminTimeZoneRoute'

const routes = [
    new SCOAdminCodeMasterRoute(),
    new SCOAdminCountryCodeRoute(),
    new SCOAdminCountryStateCodeRoute(),
    new SCOAdminTimeZoneRoute()
]

export default routes