import { TablePage, ViewPage , AddPage, EditPage} from 'src/views/sco-admin/CodeMaster'
import pathMap from 'src/paths/pathMap'


class SCOAdminCountryCodeRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_ADMIN_CODE_TYPE_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_ADMIN_CODE_TYPE_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_ADMIN_CODE_TYPE_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_ADMIN_CODE_TYPE_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}

export default SCOAdminCountryCodeRoute