import { JITEnquiryPage, JITRequestPage } from 'src/views/forwardhub/jitrequest'

import pathMap from 'src/paths/PathMap_ForwardHub'

class JITRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.JIT_SEARCH_HOME,
        component: JITEnquiryPage
      },
      {
        exact: true,
        path: pathMap.JIT_REQUEST_HOME,
        component: JITRequestPage
      }
    ]
  }
}

export default JITRoute
