import {
  AddVesselPage,
  ManageVesselsTablePage,
  ViewVesselForm
} from 'src/views/vesselschedule/managevessels'

import pathMap from 'src/paths/PathMap_VesselSchedule'

class TcalVsVesselRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_VESSELS_VIEW,
        component: ManageVesselsTablePage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_VESSELS_ADD,
        component: AddVesselPage
      },
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_VESSELS_VAMEND,
        component: ViewVesselForm
      }
    ]
  }
}

export default TcalVsVesselRoute
