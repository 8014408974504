import pathMap from 'src/paths/pathMap'
import {IntroPageCN} from 'src/views/intropage'

class IntroPageCNRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.HOME_CN_VIEW,
                component: IntroPageCN
            }
        ]
    }   
}

export default IntroPageCNRoute