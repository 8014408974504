import {
  ArticlePage,
  HomePage,
  ListPage,
  SearchPage,
  SiteContentPage
} from 'src/views/homepage'

import pathMap from 'src/paths/pathMap'

class HomePageRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.HOME_VIEW,
        component: HomePage
      },
      {
        exact: true,
        path: pathMap.SEARCH_VIEW,
        component: SearchPage
      },
      {
        exact: true,
        path: pathMap.LIST_VIEW,
        component: ListPage
      },
      {
        exact: true,
        path: pathMap.SITE_CONTENT_VIEW,
        component: SiteContentPage
      },
      {
        exact: true,
        path: pathMap.ARTICLE_VIEW,
        component: ArticlePage
      },
      {
        exact: true,
        path: pathMap.NEWS_VIEW,
        component: ArticlePage
      },
      {
        exact: true,
        path: pathMap.VIDEO_VIEW,
        component: ArticlePage
      },
      {
        exact: true,
        path: pathMap.EVENT_VIEW,
        component: ArticlePage
      }
    ]
  }
}

export default HomePageRoute
