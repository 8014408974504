import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/account-setup/shipperrequesttype'
import pathMap from 'src/paths/PathMap_AccountSetup'

class ShipperRequestTypeRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.SHIPPER_REQUEST_TYPE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.SHIPPER_REQUEST_TYPE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.SHIPPER_REQUEST_TYPE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.SHIPPER_REQUEST_TYPE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default ShipperRequestTypeRoute
