import { ContractLandingPage, AddPage, EditPage, ViewPage } from 'src/views/sco-ratemgmt/contract'
import pathMap from 'src/paths/PathMap_SCO_RateMgmt'

class ContractRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_RATE_CONTRACT_LIST_VIEW,
                component: ContractLandingPage
            },
            {
                exact: true,
                path: pathMap.SCO_RATE_CONTRACT_ADD_PAGE,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_RATE_CONTRACT_EDIT_PAGE,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SCO_RATE_CONTRACT_DETAILS_PAGE,
                component: ViewPage
            }

        ]
    }
}

export default ContractRoute