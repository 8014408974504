import {
  EssDocsSSOPage
} from 'src/views/essdocs'
import pathMap from 'src/paths/PathMap_EssDocs'
  
  class EssDocsRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.ESSDOCS_SSO,
          component: EssDocsSSOPage
        },
      ]
    }
  }
  
  export default EssDocsRoute
  