import { ManageBlacklistedCarriersTablePage } from 'src/views/vesselschedule/manageblacklistedcarriers'
import pathMap from 'src/paths/PathMap_VesselSchedule'

class ManageBlacklistedCarriersRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TCAL_VS_MANAGE_BLA_CAR_LIST_VIEW,
        component: ManageBlacklistedCarriersTablePage
      }
    ]
  }
}

export default ManageBlacklistedCarriersRoute
