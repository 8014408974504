import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
  CreateDashTemplatePage,
  EditDashTemplatePage
} from 'src/views/forwardhub/dashexporttemplate'
import pathMap from 'src/paths/PathMap_ForwardHub'

class CompanyForTemplateRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.DASH_EXPORT_TEMPLATE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.DASH_EXPORT_TEMPLATE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.DASH_EXPORT_TEMPLATE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.DASH_EXPORT_TEMPLATE_DETAILS_VIEW,
        component: ViewPage
      },
     {
       exact: true,
       path: pathMap.DASH_EXPORT_TEMPLATE_DETAILS_ADD_VIEW,
       component: CreateDashTemplatePage
     },
    {
      exact: true,
      path: pathMap.DASH_EXPORT_TEMPLATE_DETAILS_EDIT_VIEW,
      component: EditDashTemplatePage
    }
    ]
  }
}

export default CompanyForTemplateRoute
