import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/forwardhub/dashuserloadstatus'
import pathMap from 'src/paths/PathMap_ForwardHub'

class DashUserLoadStatusRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.DASH_USER_LOAD_STATUS_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.DASH_USER_LOAD_STATUS_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.DASH_USER_LOAD_STATUS_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.DASH_USER_LOAD_STATUS_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default DashUserLoadStatusRoute
