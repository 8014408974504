import {MassRecommendPage, MassRecommendResultPage, MassRecommendUploadPage} from 'src/views/intelligent-advisory'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class MassRecommendRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CALISTA_ADVISORY_MASSRECOMMEND,
        component: MassRecommendPage
      },
      {
        exact: true,
        path: pathMap.CALISTA_ADVISORY_MASSRECOMMEND_UPLOAD_TYPE,
        component: MassRecommendUploadPage
      },
      {
        exact: true,
        path: pathMap.CALISTA_ADVISORY_MASSRECOMMEND_RESULT_TYPE,
        component: MassRecommendResultPage
      }
    ]
  }
}

export default MassRecommendRoute
