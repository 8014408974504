import SCOJobConfigRoute from './SCOJobConfigRoute'
import SCOMyJobRoute from './SCOMyJobRoute'
import SCOJobAttributeRoute from './SCOJobAttributeRoute'
import SCODocumentConversionRoute from './SCODocumentConversionRoute'
import SCOJobRequestRoute from './SCOJobRequestRoute'
import SCOJMCustomAttrRoute from './SCOJMCustomAttrRoute'

const routes = [
    new SCOJobConfigRoute(),
    new SCOMyJobRoute(),
    new SCOJobAttributeRoute(),
    new SCODocumentConversionRoute(),
    new SCOJobRequestRoute(),
    new SCOJMCustomAttrRoute()
]

export default routes