import {
  MySIPage,
  MySIViewSIPage,
  MySIVManageSIPage,
  MySIVManageSIReviewPage,
  MySIVManageSISubmitPage
} from 'src/views/shippinginstruction/mysi'
import pathMap from 'src/paths/PathMap_ShippingInstruction'

class ShippingInstrutionRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.MY_SI,
        component: MySIPage
      },
      {
        exact: true,
        path: pathMap.MY_SI_VIEW_SI,
        component: MySIViewSIPage
      },
      {
        exact: true,
        path: pathMap.MY_SI_MANAGE_SI,
        component: MySIVManageSIPage
      },
      {
        exact: true,
        path: pathMap.MY_SI_MANAGE_SI_REVIEW,
        component: MySIVManageSIReviewPage
      },
      {
        exact: true,
        path: pathMap.MY_SI_MANAGE_SI_SUBMITTED,
        component: MySIVManageSISubmitPage
      }
    ]
  }
}

export default ShippingInstrutionRoute
