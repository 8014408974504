import { ShippingInfoPrefPage } from 'src/views/company-preference/shippinginfo'
import { EditPage } from 'src/views/shippinginstruction/companypreference'
import pathMap from 'src/paths/PathMap_CompanyPreference'

class ShippingInfoPrefRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.SI_PREF_SETTING,
        component: ShippingInfoPrefPage
      },
      {
        exact: true,
        path: pathMap.SI_COMPANY_PREFERENCE_EDIT_VIEW,
        component: EditPage
      }
    ]
  }
}

export default ShippingInfoPrefRoute
