import {
    TablePage,
    ViewPage,
    EditPage,
    AddPage
}  from 'src/views/cia-admin/fta-mapping'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class CalistaAdvisoryFtaMappingRoute{
    getConfigurations(){
        return[
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact:true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_FTA_MAPPING_ADD_VIEW,
                component: AddPage
            }
        ]
    }
}

export default CalistaAdvisoryFtaMappingRoute