import ManagePermitRoute from './ManagePermitRoute'
import CreatePermitRoute from './CreatePermitRoute'
import DeclarantMaintenanceRoute from './DeclarantMaintenanceRoute'

const routes = [
    new ManagePermitRoute(), 
    new CreatePermitRoute(),
    new DeclarantMaintenanceRoute()
]

export default routes