import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/account-setup/user-group-master'
import pathMap from 'src/paths/PathMap_AccountSetup'

class TcalAsUserGroupMasterRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.TCAL_AS_USER_GROUP_MASTER_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.TCAL_AS_USER_GROUP_MASTER_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.TCAL_AS_USER_GROUP_MASTER_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.TCAL_AS_USER_GROUP_MASTER_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default TcalAsUserGroupMasterRoute
