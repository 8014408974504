import { AddPage, EditPage, ViewPage } from 'src/views/cec-admin/shipment-providers/connector-mapping'
import pathMap from 'src/paths/PathMap_CeCAdmin'

export default class ShipmentProviderConnectorMappingRoute {
  getConfigurations() {
    return [

      {
        exact: true,
        path: pathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.SHIPMENT_PROVIDER_CONNECTOR_MAPPING_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}