import {
  ResultPage,
  CalculateAdvanPage,
  CalculatePage,
  AuditLogPage
} from '../../views/carbon-footprint-calculator/eco-calculator/index.js'
import pathMap from 'src/paths/PathMap_CarbonFootprint'

class EcoCalculatorRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ECO_CALCULATOR_LIST_VIEW,
        component: CalculatePage
      },
      {
        exact: true,
        path: pathMap.ECO_CALCULATOR_ADVAN_VIEW,
        component: CalculateAdvanPage
      },
      {
        exact: true,
        path: pathMap.ECO_CALCULATOR_RESULT_VIEW,
        component: ResultPage
      },
      {
        exact: true,
        path: pathMap.AUDIT_LOG,
        component: AuditLogPage
      }
    ]
  }
}
export default EcoCalculatorRoute
