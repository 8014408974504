import { TablePage, EditPage } from 'src/views/sco-invmgmt/principalmapping/mapping/index'
import { PrincipalTablePage, PrincipalViewPage } from 'src/views/sco-invmgmt/principalmapping/myinventory/index'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'
import scoAdminPathMap from 'src/paths/pathMap'



class InvPrincipalMappingRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: scoAdminPathMap.SCO_ADMIN_PRINCIPAL_MAPPING,
                component: TablePage
            },
            {
                exact: true,
                path: scoAdminPathMap.SCO_ADMIN_PRINCIPAL_MAPPING_PARTIES_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_MY_PRINCIPAL_LIST,
                component: PrincipalTablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_MY_PRINCIPAL_DETAILS_VIEW,
                component: PrincipalViewPage
            }

        ]
    }
}

export default InvPrincipalMappingRoute