import {
	ListPage,
	AddPage,
	AddSuccessPage,
	EditPage,
	EditSuccessPage,
	ViewPage,
	UploadListPage
} from 'src/views/ping/my-milestones'
import pathMap from 'src/paths/PathMap_Ping'

class PingMyMilestonesRoute {
	getConfigurations() {
		return [
			//* CRUD pages -----------------------------------------------------
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES,
				component: ListPage
			},
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES_LIST_VIEW,
				component: ListPage
			},
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES_ADD_VIEW,
				component: AddPage
			},
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES_ADD_SUCCESS_VIEW,
				component: AddSuccessPage
			},
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES_EDIT_VIEW,
				component: EditPage
			},
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES_EDIT_SUCCESS_VIEW,
				component: EditSuccessPage
			},
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES_DETAILS_VIEW,
				component: ViewPage
			},

			//* Upload pages ---------------------------------------------------
			{
				exact: true,
				path: pathMap.PING_MY_MILESTONES_UPLOAD_LIST_VIEW,
				component: UploadListPage
			},
		]
	}
}

export default PingMyMilestonesRoute
