import {
    LabelPage
} from 'src/views/ecommerce/label'
import pathMap from "src/paths/PathMap_EcommerceLabel";

export default class EcommerceLabelRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.LABEL_PAGE,
                component: LabelPage
            }
        ]
    }
}