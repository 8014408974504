import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/edi/sinotransregistration'
import pathMap from 'src/paths/PathMap_EDI'

class SinotransRegistrationRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.SINOTRANS_REGISTRATION_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.SINOTRANS_REGISTRATION_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.SINOTRANS_REGISTRATION_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.SINOTRANS_REGISTRATION_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default SinotransRegistrationRoute
