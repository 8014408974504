import { EditPage } from 'src/views/sco-invmgmt/def-loc-config/index'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'


class InvDefLocConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_DEF_LOC_CONFIG_EDIT,
                component: EditPage
            }
        ]
    }
}

export default InvDefLocConfigRoute