import {
  TablePage,
  EditPage,
  ViewPage,
  AddPage,
} from 'src/views/scojob/jmcustomattribute'
import pathMap from "src/paths/PathMap_SCOJMCustomAttr";

export default class SCOJMCustomAttrRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.SCO_JM_CUSTOM_ATTR_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.SCO_JM_CUSTOM_ATTR_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.SCO_JM_CUSTOM_ATTR_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.SCO_JM_CUSTOM_ATTR_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}