import CalistaAdvisoryPartyConfigRoute from "./CalistaAdvisoryPartyConfigRoute"
import CalistaAdvisorySystemConfigRoute from "./CalistaAdvisorySystemConfigRoute"
import CalistaAdvisoryCountryRoute from "./CalistaAdvisoryCountryRoute"
import CalistaAdvisoryFtaMappingRoute from './CalistaAdvisoryFtaMappingRoute'
import CiaAdminMassRecommendRoute from './CiaAdminMassRecommendRoute'

const routes = [
    new CalistaAdvisoryPartyConfigRoute(),
    new CalistaAdvisorySystemConfigRoute(),
    new CalistaAdvisoryCountryRoute(),
    new CalistaAdvisoryFtaMappingRoute(),
    new CiaAdminMassRecommendRoute()
]

export default routes