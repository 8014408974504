import {
  AdminLogPage
} from 'src/views/forwardhub/adminlog'
import pathMap from 'src/paths/PathMap_ForwardHub'

class AdminlogRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ADMINLOG_PATH,
        component: AdminLogPage
      }
    ]
  }
}

export default AdminlogRoute
