import {
    TablePage
} from 'src/views/ping-admin/vessel-voyage-info'
import pathMap from 'src/paths/PathMap_Ping'

class PingSysConfigRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.PING_ADMIN_VESSEL_VOYAGE_INFO_LIST_VIEW,
                component: TablePage
            },
        ]
    }
}

export default PingSysConfigRoute
