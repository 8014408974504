import { HinterlandDestTablePage } from 'src/views/nmot/hinterlanddest'
import { ApiLogsTablePage } from 'src/views/nmot/apilog'
import { AdminTablePage } from 'src/views/nmot/admin'
import { AddPage, EditPage, ViewPage } from 'src/views/nmot/add-nmot'
import { DashboardTablePage } from 'src/views/nmot/dashboard'
import { UploadTablePage } from 'src/views/nmot/upload-nmot'
import pathMap from 'src/paths/PathMap_Nmot'

class NmotRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.NMOT_H_DEST,
        component: HinterlandDestTablePage
      },
      {
        exact: true,
        path: pathMap.NMOT_LOGS,
        component: ApiLogsTablePage
      },
      {
        exact: true,
        path: pathMap.NMOT_ADMIN,
        component: AdminTablePage
      },
      {
        exact: true,
        path: pathMap.NMOT_ADD,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.NMOT_EDIT,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.NMOT_VIEW,
        component: ViewPage
      },
      {
        exact: true,
        path: pathMap.NMOT_UPLOAD,
        component: UploadTablePage
      },
      {
        exact: true,
        path: pathMap.NMOT_DASHBOARD,
        component: DashboardTablePage
      }
    ]
  }
}

export default NmotRoute
