import ClogsConfigRoute from './ClogsConfigRoute'
import ContainerTypeRoute from './ContainerTypeRoute'
import JsonSchemaRoute from './JsonSchemaRoute'
import ModuleRoute from './ModuleRoute'
import SequenceRoute from './SequenceRoute'
import BillingAuditRoute from './BillingAuditRoute'
import CustomUserAccountRoute from './CustomUserAccountRoute'

const routes = [
    new ClogsConfigRoute(),
    new SequenceRoute(),
    new JsonSchemaRoute(),
    new ContainerTypeRoute(),
    new ModuleRoute(),
    new BillingAuditRoute(),
    new CustomUserAccountRoute()
]

export default routes
