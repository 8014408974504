import { VolumeReportPage } from 'src/views/forwardhub/volumeReport'

import pathMap from 'src/paths/PathMap_ForwardHub'

class VolumeReportRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.VOLUME_REPORT,
        component: VolumeReportPage
      }
    ]
  }
}

export default VolumeReportRoute
