import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/cec-admin/do-uom-mapping'
import pathMap from 'src/paths/PathMap_CeCAdmin'

export default class CecDoUomMappingRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.CEC_DO_UOM_MAPPING_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CEC_DO_UOM_MAPPING_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.CEC_DO_UOM_MAPPING_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.CEC_DO_UOM_MAPPING_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}
