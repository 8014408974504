import {
	TablePage,
	ViewPage
} from 'src/views/cia-admin/mass-recommend'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class CiaAdminMassRecommendRoute {
	getConfigurations() {
		return [
			{
				exact: true,
				path: pathMap.CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND_LIST_VIEW,
				component: TablePage
			},
			{
				exact: true,
				path: pathMap.CALISTA_ADVISORY_ADMIN_MASS_RECOMMEND_DETAILS_VIEW,
				component: ViewPage
			}
		]
	}
}

export default CiaAdminMassRecommendRoute
