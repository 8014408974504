import {
    TablePage,
    ViewPage,
    EditPage
}  from 'src/views/cia-admin/system-config'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class CalistaAdvisorySystemConfigRoute{
    getConfigurations(){
        return[
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact:true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_SYSTEM_CONFIG_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}

export default CalistaAdvisorySystemConfigRoute