import ReportManagementRoute from './ReportManagementRoute'
import ReportDownloadRoute from "./ReportDownloadRoute";
import ReportSchedulerRoute from './ReportSchedulerRoute';
import GeneratedReportRoute from './GeneratedReportRoute'
import ReportSchedulerCustomerRoute from './ReportSchedulerCusomerRoute'
import GeneratedReportCustomerRoute from './GeneratedReportRouteCustomer'



const routes = [
    new ReportManagementRoute(),
    new ReportDownloadRoute(),
    new ReportSchedulerRoute(),
    new GeneratedReportRoute(),
    new ReportSchedulerCustomerRoute(),
    new GeneratedReportCustomerRoute()
]

export default routes
