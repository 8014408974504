import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/account-setup/accesslevelcompany'
import pathMap from 'src/paths/PathMap_AccountSetup'

class AccessLevelCompanyRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.ACCESS_LEVEL_COMPANY_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default AccessLevelCompanyRoute
