import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
} from 'src/views/report/managereport'
import pathMap from "src/paths/PathMap_Report";

export default class ReportManagementRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.REPORT_MANAGEMENT_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.REPORT_MANAGEMENT_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.REPORT_MANAGEMENT_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.REPORT_MANAGEMENT_DETAILS_VIEW,
                component: ViewPage
            }
        ]
    }
}