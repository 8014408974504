import ProdMgtRoute from './ProdMgtRoute'
import InboundRoute from './InboundRoute'
import StockRoute from './StockRoute'
import OutboundRoute from './OutboundRoute'
import VolumeReportRoute from './VolumeReportRoute'
import DashboardRoute from './DashboardRoute'
import AdminlogRoute from './AdminlogRoute'
import DashLoadStatusRoute from './DashLoadStatusRoute'
import DashUserLoadStatusRoute from './DashUserLoadStatusRoute'
import CompanyForTemplateRoute from './CompanyForTemplateRoute'
import FTMRoute from './FTMRoute'
import JITRoute from './JITRoute'
import CommonConfigRoute from './CommonConfigRoute'
import WarehouseTrackingRoute from './WarehouseTrackingRoute'
const routes = [
    new ProdMgtRoute(),
    new InboundRoute(),
    new StockRoute(),
    new OutboundRoute(),
    new VolumeReportRoute(),
    new DashboardRoute(),
    new AdminlogRoute(),
    new DashLoadStatusRoute(),
    new DashUserLoadStatusRoute(),
    new CompanyForTemplateRoute(),
    new FTMRoute(),
    new JITRoute(),
    new CommonConfigRoute(),
    new WarehouseTrackingRoute()
]

export default routes
