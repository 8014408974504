import {
    TablePage,
    ViewPage,
    EditPage,
    AddPage
}  from 'src/views/cia-admin/country'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class CalistaAdvisoryCountryRoute{
    getConfigurations(){
        return[
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact:true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.CALISTA_ADVISORY_ADMIN_COUNTRY_ADD_VIEW,
                component: AddPage
            }
        ]
    }
}

export default CalistaAdvisoryCountryRoute