import { InboundPage, InboundPageLate, InboundPageReinstate, InboundTabPage } from 'src/views/forwardhub/inbound'

import pathMap from 'src/paths/PathMap_ForwardHub'

class InboundRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.INBOUND_NORMAL,
        component: InboundPage
      },
      {
        exact: true,
        path: pathMap.INBOUND_LATE,
        component: InboundPageLate
      },
      {
        exact: true,
        path: pathMap.INBOUND_REINSTATE,
        component: InboundPageReinstate
      },
      {
        exact: true,
        path: pathMap.INBOUND_HOME,
        component: InboundTabPage
      }
    ]
  }
}

export default InboundRoute
