import {AcctMgmtPage, AdminAcctMgmtCompanyViewPage, AdminAcctMgmtPage} from 'src/views/cia-company-admin'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class AdminAcctMgmtRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CALISTA_ADMIN_ACCOUNT_MANAGEMENT,
        component: AdminAcctMgmtPage
      },
      {
        exact: true,
        path: pathMap.CALISTA_ADMIN_ACCOUNT_MANAGEMENT_COMPANY_VIEW,
        component: AdminAcctMgmtCompanyViewPage
      }
    ]
  }
}

export default AdminAcctMgmtRoute
