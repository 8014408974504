import React from 'react'
import { CalculatorPage, ResultPage } from 'src/views/intelligent-advisory'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

class LandedCostRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CALISTA_ADVISORY_LCC,
        component: CalculatorPage
      },
      {
        exact: true,
        path: pathMap.CALISTA_ADVISORY_LCC_RESULT,
        component: ResultPage
      }
    ]
  }
}

export default LandedCostRoute
