import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/edi/batchmilestone'
import pathMap from 'src/paths/PathMap_EDI'

class BatchMilestoneRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.BATCH_MILESTONE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.BATCH_MILESTONE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.BATCH_MILESTONE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.BATCH_MILESTONE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default BatchMilestoneRoute
