import {
  AddPage,
  EditPage,
  TablePage,
  ViewPage,
} from 'src/views/logisticsadmin/customuseraccount'

import pathMap from 'src/paths/PathMap_LogisticsAdmin'

class CustomUserAccountRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.CUSTOM_USER_ACCOUNT_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.CUSTOM_USER_ACCOUNT_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.CUSTOM_USER_ACCOUNT_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.CUSTOM_USER_ACCOUNT_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default CustomUserAccountRoute