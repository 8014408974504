import {
    TablePage,
    AddPage,
    EditPage,
    ViewPage,
  } from 'src/views/vesselschedule/codemapping'
  import pathMap from 'src/paths/pathMap'
  
  class CodeMappingRoute {
    getConfigurations() {
      return [
        {
          exact: true,
          path: pathMap.CODE_MAPPING_LIST_VIEW,
          component: TablePage
        },
        {
          exact: true,
          path: pathMap.CODE_MAPPING_ADD_VIEW,
          component: AddPage
        },
        {
          exact: true,
          path: pathMap.CODE_MAPPING_EDIT_VIEW,
          component: EditPage
        },
        {
          exact: true,
          path: pathMap.CODE_MAPPING_DETAILS_VIEW,
          component: ViewPage
        }
      ]
    }
  }
  
  export default CodeMappingRoute
  