import {
  AddPage,
  EditPage,
  TablePage,
  ViewPage,
} from 'src/views/logisticsadmin/module'

import pathMap from 'src/paths/PathMap_LogisticsAdmin'

class ModuleRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.MODULE_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.MODULE_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.MODULE_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.MODULE_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default ModuleRoute
