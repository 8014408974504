import pathMap from 'src/paths/pathMap'
import { IntroPage } from 'src/views/intropage'

class IntroPageRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.HOME_VIEW,
                component: IntroPage
            }
        ]
    }   
}

export default IntroPageRoute