import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/account-setup/companyforshipperrequest'
import pathMap from 'src/paths/PathMap_AccountSetup'

class CompanyForShipperRequestRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.COMPANY_FOR_SHIPPER_REQUEST_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.COMPANY_FOR_SHIPPER_REQUEST_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.COMPANY_FOR_SHIPPER_REQUEST_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.COMPANY_FOR_SHIPPER_REQUEST_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default CompanyForShipperRequestRoute
