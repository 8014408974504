import { StockSearchPage, StockUploadPage, StockTabPage } from 'src/views/forwardhub/stock'
import { StockTransferPage } from 'src/views/forwardhub/stockTransfer'
import pathMap from 'src/paths/PathMap_ForwardHub'

class StockRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.STOCK_SEARCH_HOME,
        component: StockSearchPage
      },
      {
        exact: true,
        path: pathMap.STOCK_UPLOAD_HOME,
        component: StockUploadPage
      },
      {
        exact: true,
        path: pathMap.STOCK_HOME,
        component: StockTabPage
      },
      {
        exact: true,
        path: pathMap.STOCK_TRANSFER_HOME,
        component: StockTransferPage
      }
    ]
  }
}

export default StockRoute
