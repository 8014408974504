import AccessLevelCompanyCustomRuleRoute from './AccessLevelCompanyCustomRuleRoute'
import AccessLevelCompanyRoute from './AccessLevelCompanyRoute'
import AccessLevelMasterRoute from './AccessLevelMasterRoute'
import AccessLevelRuleRoute from './AccessLevelRuleRoute'
import AccessLevelUserRoute from './AccessLevelUserRoute'
import CompanyForShipperRequestRoute from './CompanyForShipperRequestRoute'
import CompanyForUserGroupRoute from './CompanyForUserGroupRoute'
import ShipperRequestTypeRoute from './ShipperRequestTypeRoute'
import SubscriptionRoute from './SubscriptionRoute'
import TcalAsUserGroupMasterRoute from './TcalAsUserGroupMasterRoute'
import TcalPartyCarrierCodeRoute from './TcalPartyCarrierCodeRoute'
import TtfbTprPartyRoute from './TtfbTprPartyRoute'
import UserForShipperRequestRoute from './UserForShipperRequestRoute'
import UserForUserGroupRoute from './UserForUserGroupRoute'
import AccessLevelUserAdminRoute from './AccessLevelUserAdminRoute';
import NBSChargeCodeMappingRoute from './NBSChargeCodeMappingRoute'
import NBSBillingConfigRoute from "./NBSBillingConfigRoute";
import NBSFileRoute from "./NBSFile";

const routes = [
    new AccessLevelCompanyCustomRuleRoute(),
    new AccessLevelCompanyRoute(),
    new AccessLevelMasterRoute(),
    new AccessLevelRuleRoute(),
    new AccessLevelUserRoute(),
    new AccessLevelUserAdminRoute(),
    new CompanyForShipperRequestRoute(),
    new CompanyForUserGroupRoute(),
    new ShipperRequestTypeRoute(),
    new SubscriptionRoute(),
    new TcalAsUserGroupMasterRoute(),
    new TcalPartyCarrierCodeRoute(),
    new TtfbTprPartyRoute(),
    new UserForShipperRequestRoute(),
    new UserForUserGroupRoute(),
    new NBSChargeCodeMappingRoute(),
    new NBSBillingConfigRoute(),
    new NBSFileRoute()
]

export default routes
