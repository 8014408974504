import { OutboundUploadPage, OutboundCreatePage, OutboundSearchPage, OutboundPageTabs, OutboundPageTabsPage } from 'src/views/forwardhub/outbound'

import pathMap from 'src/paths/PathMap_ForwardHub'

class OutboundRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.OUTBOUND_HOME,
        component: OutboundPageTabsPage
      },
      {
        exact: true,
        path: pathMap.OUTBOUND_CREATE,
        component: OutboundCreatePage
      },
      {
        exact: true,
        path: pathMap.OUTBOUND_UPLOAD,
        component: OutboundPageTabsPage
      },
      {
        exact: true,
        path: pathMap.OUTBOUND_SEARCH,
        component: OutboundSearchPage
      }
    ]
  }
}

export default OutboundRoute
