import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/account-setup/userforusergroup'
import pathMap from 'src/paths/PathMap_AccountSetup'

class UserForUserGroupRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.USER_FOR_USER_GROUP_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.USER_FOR_USER_GROUP_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.USER_FOR_USER_GROUP_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.USER_FOR_USER_GROUP_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default UserForUserGroupRoute
