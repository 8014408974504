import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/edi/batchbookingrequest'
import pathMap from 'src/paths/PathMap_EDI'

class BatchBookingRequestRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.BATCH_BOOKING_REQUEST_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.BATCH_BOOKING_REQUEST_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.BATCH_BOOKING_REQUEST_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.BATCH_BOOKING_REQUEST_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default BatchBookingRequestRoute
