import { TablePage, ViewPage, EditPage, AddPage, AdminTablePage, AdminViewPage } from 'src/views/sco-invmgmt/supplier/index'
import pathMap from 'src/paths/PathMap_SCO_InvMgmt'


class InvSupplierRoute {
    getConfigurations() {
        return [
            {
                exact: true,
                path: pathMap.SCO_INV_SUPPLIER_LIST,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SUPPLIER_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SUPPLIER_EDIT_VIEW,
                component: EditPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_SUPPLIER_CREATE_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_SUPPLIER_LIST,
                component: AdminTablePage
            },
            {
                exact: true,
                path: pathMap.SCO_INV_ADMIN_SUPPLIER_DETAILS_VIEW,
                component: AdminViewPage
            },
        ]
    }
}

export default InvSupplierRoute