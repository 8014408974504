import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/cec-admin/UomConversion'
import pathMap from 'src/paths/PathMap_CeCAdmin'

export default class CecUomConversionRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.CEC_UOM_CONVERSION_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CEC_UOM_CONVERSION_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.CEC_UOM_CONVERSION_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.CEC_UOM_CONVERSION_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}
