import { TablePage, ViewPage, AddPage, EditPage } from 'src/views/cec-admin/postcode-config'
import pathMap from 'src/paths/PathMap_CeCAdmin'

export default class CecPostcodeConfigRoute {
    getConfigurations() {
        return [

            {
                exact: true,
                path: pathMap.CEC_POSTCODE_CONFIG_LIST_VIEW,
                component: TablePage
            },
            {
                exact: true,
                path: pathMap.CEC_POSTCODE_CONFIG_DETAILS_VIEW,
                component: ViewPage
            },
            {
                exact: true,
                path: pathMap.CEC_POSTCODE_CONFIG_ADD_VIEW,
                component: AddPage
            },
            {
                exact: true,
                path: pathMap.CEC_POSTCODE_CONFIG_EDIT_VIEW,
                component: EditPage
            }
        ]
    }
}
