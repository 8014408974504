import {
  TablePage,
  AddPage,
  EditPage,
  ViewPage,
} from 'src/views/forwardhub/prodmgt'
import pathMap from 'src/paths/PathMap_ForwardHub'

class ProdMgtRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.PROD_MGT_LIST_VIEW,
        component: TablePage
      },
      {
        exact: true,
        path: pathMap.PROD_MGT_ADD_VIEW,
        component: AddPage
      },
      {
        exact: true,
        path: pathMap.PROD_MGT_EDIT_VIEW,
        component: EditPage
      },
      {
        exact: true,
        path: pathMap.PROD_MGT_DETAILS_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default ProdMgtRoute
